import React, {useContext, useEffect, useState} from 'react';
import './Footer.scss';
import logotype from './images/logo.svg';
import {Link, useLocation} from 'react-router-dom';
import {i18nContext} from '../../../components/i18n/I18nProvider';

type FooterProps = {
    onOpenEvaluate: () => void;
    onOpenFeedback: (type: string) => void;
}
const Footer: React.FC<FooterProps> = (props) => {
    const {i18n} = useContext(i18nContext);
    const [isBuyPage, setBuyPage] = useState(true);

    const location = useLocation();
    useEffect(() => {
        setBuyPage(window.location.href.includes('/buy'));
    }, [location]);
    return (<footer className="footer">
        <div className="row between-xl between-lg">
            <div className="col-xs-12 col-sm-5 col-md-5 col-lg-4 footer__left">
                <Link to={'/buy'}>
                    <img className="footer__logotype" src={logotype} alt={'domus'} />
                </Link>
                <div className="footer__copyright hide-xs show-sm">© 2024 Selecta</div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 footer__middle">
                <div className="footer__navigation-item">
                    <Link to={'/buy'}>
                        {i18n.t('footer.links.buy')}
                    </Link>
                </div>
                <div className="footer__navigation-item" onClick={() => {props.onOpenEvaluate();}}>
                    {i18n.t('footer.links.evaluate')}
                </div>
                <div className="footer__navigation-item">
                    <Link to={'/extras'}>
                        {i18n.t('footer.links.extras')}
                    </Link>
                </div>
                <div className="footer__navigation-item">
                    <Link to={'/how-it-works'}>
                        {i18n.t('footer.links.howItWorks')}
                    </Link>
                </div>
                <div className="footer__navigation-item footer__navigation-item-privacy hide-xs show-sm">
                    <Link to={'/privacy'}>
                        {i18n.t('footer.links.privacy')}
                    </Link>
                </div>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-4 col-lg-3 footer__right">
                <div className="contact-row contact-row--location">
                    <i className="icon-location" />
                    <span>Plaza del Ayuntamiento 19, office 3G, Valencia</span>
                </div>
                <div className="contact-row">
                    <a href="tel:+34624294452">
                        <i className="icon-phone2" />
                        +34 624 29 44 52
                    </a>
                </div>
                <div className="contact-row">
                    <a className="contact-row" href="mailto:info@reselecta.com?subject=Question regarding selecta">
                        <i className="icon-email" />
                        info@reselecta.com
                    </a>
                </div>
                <div className="show-xs hide-sm footer__info">
                    <div className="footer__copyright">© 2024 Selecta</div>
                    <div className="footer__navigation-item footer__navigation-item-privacy">
                        <Link to={'/privacy'}>
                            {i18n.t('footer.links.privacy')}
                        </Link>
                    </div>
                </div>
                <div className="social-container">
                    <div className="social-icon">
                        <a href="https://t.me/selectaRE">
                            <i className="icon-telegram" />
                        </a>
                    </div>
                    <div className="social-icon">
                        <i className="icon-whatsapp" />
                    </div>
                    <div className="social-icon">
                        <a href="https://www.instagram.com/selecta_re/">
                            <i className="icon-instagram" />
                        </a>
                    </div>
                    <div className="social-icon">
                        <a href="https://www.facebook.com/profile.php?id=61566480390245">
                            <i className="icon-facebook" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>)
}

export default Footer;
