import React, {useContext} from 'react';
import {RealEstate} from '../../../../store/components/RealEstateStore';
import './RealEstateCard.scss';
import {Link} from 'react-router-dom';
import {currencyFormatter} from '../../../../utils/functions';
import {i18nContext} from '../../../../components/i18n/I18nProvider';

type RealEstateCardProps = {
    realEstate: RealEstate;
}

const RealEstateCard: React.FC<RealEstateCardProps> = ({realEstate}) => {
    const {i18n} = useContext(i18nContext);
    const getAddress = () => {
        // const address = realEstate.presentationAddress.split(', ');
        // address.shift()
        // return address.join(', ');
        return realEstate.presentationManualAddress;
    }
    return (<><div className="hide-xs show-sm col-sm-6 col-md-4 col-lg-4">
            <Link to={`/catalog/realEstate/${realEstate.id}`} className="real-estate-card-link">
                <div className="real-estate-card-preview">
                    {/*@ts-ignore*/}
                    <div className="real-estate-catalog-preview" style={{backgroundImage: "url(" + realEstate.previews[0].imageUrl + ")"}}></div>
                    <div className="real-estate-catalog-price">{currencyFormatter(realEstate.catalogPrice || 0, true)}</div>
                </div>
                <div className="real-estate-description">
                    <div className="real-estate-features">
                        <div className="real-estate-feature">
                            <b>{realEstate.bedroomCount}</b> {i18n.t('catalog.card.bedrooms')}
                        </div>
                        <div className="real-estate-feature-separator"></div>
                        <div className="real-estate-feature">
                            <b>{realEstate.bathroomCount}</b> {i18n.t('catalog.card.bathrooms')}
                        </div>
                        <div className="real-estate-feature-separator"></div>
                        <div className="real-estate-feature">
                            <b>{realEstate.livingArea} m2</b> {i18n.t('catalog.card.livingArea')}
                        </div>
                    </div>
                    <div className="real-estate-address">
                        <div className="real-estate-address-location">
                            <i className="icon-location"/>
                            {realEstate.presentationManualAddress}
                        </div>
                        <div className="real-estate-address-presentation">
                            <b>{realEstate.realEstateMarketingName}</b>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
        <div className="col-xs-12 show-xs hide-sm hide-md hide-xl">
            <Link to={`/catalog/realEstate/${realEstate.id}`} className="real-estate-card-link">
                <div className="real-estate-card-preview">
                    {/*@ts-ignore*/}
                    <div className="real-estate-catalog-preview" style={{backgroundImage: "url(" + realEstate.previews[0].imageUrl + ")"}}></div>
                    <div className="real-estate-catalog-price">{currencyFormatter(realEstate.catalogPrice || 0, true)}</div>
                </div>
                <div className="real-estate-description">
                    <div className="real-estate-address">
                        <div className="real-estate-address-location">
                            <i className="icon-location"/>
                            {getAddress()}
                        </div>
                        <div className="real-estate-address-presentation">
                            <b>{realEstate.realEstateMarketingName}</b>
                        </div>
                    </div>
                    <div className="real-estate-features">
                        <div className="real-estate-feature">
                            <b>{realEstate.bedroomCount}</b> {i18n.t('catalog.card.bedrooms')}
                        </div>
                        <div className="real-estate-feature-separator"></div>
                        <div className="real-estate-feature">
                            <b>{realEstate.bathroomCount}</b> {i18n.t('catalog.card.bathrooms')}
                        </div>
                        <div className="real-estate-feature-separator"></div>
                        <div className="real-estate-feature">
                            <b>{realEstate.livingArea} m2</b> {i18n.t('catalog.card.livingArea')}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    </>)
}

export default RealEstateCard;
