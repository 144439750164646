import React, {useContext, useEffect, useState} from 'react';
import './Buy.scss';
import banner from './images/banner-background.jpg';
import {RealEstate, realEstateStoreContext} from '../../store/components/RealEstateStore';
import Button from '../../components/ui/Button/Button';
import {observer} from 'mobx-react';
import {i18nContext} from '../../components/i18n/I18nProvider';
import {useNavigate, useOutletContext} from 'react-router-dom';
import Searcher2 from './components/Searcher2/Searcher2';
import RealEstateCard from '../Catalog/components/RealEstateCard/RealEstateCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import sell01 from './images/sell_01.svg';
import sell02 from './images/sell_02.svg';
import sell03 from './images/sell_03.svg';
import sell04 from './images/sell_04.svg';
import sell05 from './images/sell_05.svg';
import sell06 from './images/sell_06.svg';
import sell07 from './images/sell_07.svg';
import buy01 from './images/buy_01.svg';
import buy02 from './images/buy_02.svg';
import buy03 from './images/buy_03.svg';
import buy04 from './images/buy_04.svg';
import buy05 from './images/buy_05.svg';
import buy06 from './images/buy_06.svg';
import buy07 from './images/buy_07.svg';
import gradient from './images/gradient.svg';

const Buy = observer(() => {
    const navigate = useNavigate();
    const {i18n} = useContext(i18nContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const {setPrivacyModalProps, setClientModalProps} = useOutletContext<any>();
    const [realEstates, setRealEstates] = useState<RealEstate[]>([] as RealEstate[]);

    const handleOpenEvaluate = () => {
        window.scrollTo(0, 0);
        setPrivacyModalProps({isOpen: true})
    }

    const handleOpenCalculator = () => {
        setClientModalProps({isOpen: true});
    }

    const handleNavigateToCatalog = () => {
        navigate(`/catalog?realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
    }

    useEffect(() => {
        realEstateStoreCtx.loadBestRealEstates().then((realEstates) => setRealEstates(realEstates));
    }, [realEstateStoreCtx]);

    return (<div className="home">
        <section className="home__banner">
            <div className="home__banner-scroller"></div>
            <div className="home__banner-image">
                <img src={banner} alt="domus banner"/>
            </div>
            <div className="home__banner-text">
                <h4>
                    <p>{i18n.t('buy.banner.descriptionTop')}</p>
                </h4>
                <Searcher2 />
            </div>
            <div className="home__banner-gradient"></div>
        </section>
        <section className="home__company-workflow">
            <div className="show-xs hide-sm hide-md hide-lg hide-xl" style={{width: '100%', overflow: 'hidden'}}>
                <div className="swiper-title">
                    {i18n.t('buy.companyWorkflow.header.sell')}
                </div>
                <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="swiper-sell"
                >
                    <SwiperSlide>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell01}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        01
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.1.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.1.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell02}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        02
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.2.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.2.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell03}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        03
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.3.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.3.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell04}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        04
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.4.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.4.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell05}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        05
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.5.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.5.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell06}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        06
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.6.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.6.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell07}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        07
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.7.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.7.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="company-workflow-card-action">
                    <Button onClick={handleOpenEvaluate}>{i18n.t('buy.companyWorkflow.sell.button')}</Button>
                </div>

                <div className="swiper-title">
                    {i18n.t('buy.companyWorkflow.header.buy')}
                </div>
                <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="swiper-buy"
                >
                    <SwiperSlide>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy01}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        01
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.1.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.1.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy02}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        02
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.2.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.2.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy03}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        03
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.3.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.3.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy04}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        04
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.4.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.4.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy05}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        05
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.5.title')}
                                        Checkup documents
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy06}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        06
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.6.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.6.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy07}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        07
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.7.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.7.description')}
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="company-workflow-card-action">
                    <Button onClick={handleNavigateToCatalog}>{i18n.t('buy.companyWorkflow.buy.button')}</Button>
                </div>
            </div>

            <div className="hide-xs show-sm show-md show-lg show-xl">
                <div className="hide-sm show-md show-lg show-xl row-cards-title">
                    <div className="home__company-workflow-section">
                        <div className="home__company-workflow-section-title">
                            <span>{i18n.t('buy.companyWorkflow.header.sell')}</span>
                            <img className="home__company-workflow-section-title-gradient" src={gradient} alt=""/>
                        </div>
                        <div className="home__company-workflow-section-separator">
                            <span></span>
                        </div>
                        <div className="home__company-workflow-section-title">
                            <span>{i18n.t('buy.companyWorkflow.header.buy')}</span>
                            <img className="home__company-workflow-section-title-gradient" src={gradient} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row center-xs center-sm center-md center-lg row-cards">
                    <div className="xs-12 sm-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="swiper-title hide-xs show-sm hide-md hide-lg hide-xl row-cards-title">
                            <div className="home__company-workflow-section-title">
                                <span>{i18n.t('buy.companyWorkflow.header.sell')}</span>
                                <img className="home__company-workflow-section-title-gradient" src={gradient} alt=""/>
                            </div>
                        </div>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell01}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        01
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.1.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.1.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell02}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        02
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.2.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.2.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell03}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        03
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.3.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.3.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell04}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        04
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.4.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.4.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell05}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        05
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.5.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.5.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell06}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        06
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.6.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.6.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={sell07}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        07
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.sell.7.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.sell.7.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card-action">
                            <Button onClick={handleOpenEvaluate}>{i18n.t('buy.companyWorkflow.sell.button')}</Button>
                        </div>
                    </div>
                    <div className="xs-12 sm-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="swiper-title hide-xs show-sm hide-md hide-lg hide-xl">Buy</div>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy01}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        01
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.1.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.1.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy02}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        02
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.2.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.2.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy03}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        03
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.3.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.3.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy04}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        04
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.4.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.4.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy05}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        05
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.5.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.5.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy06}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        06
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.6.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.6.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card company-workflow-card--blue">
                            <div className="company-workflow-card-top">
                                <img className="company-workflow-card-image" src={buy07}  alt={''}/>
                                <div className="card-top-description">
                                    <div className="card-top-description__counter">
                                        07
                                    </div>
                                    <div className="card-top-description__title">
                                        {i18n.t('buy.companyWorkflow.buy.7.title')}
                                    </div>
                                </div>
                            </div>
                            <div className="company-workflow-card-bottom">
                                {i18n.t('buy.companyWorkflow.buy.7.description')}
                            </div>
                        </div>
                        <div className="company-workflow-card-action">
                            <Button onClick={handleNavigateToCatalog}>{i18n.t('buy.companyWorkflow.buy.button')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {
            realEstates.length > 0 && <section className="home__best-offers">
                <h2>{i18n.t('buy.bestOffers.title')}</h2>
                <div className="row start-xs start-sm center-md center-lg">
                    {realEstates.map((realEstate: RealEstate) => <RealEstateCard realEstate={realEstate} key={realEstate.id}/>)}
                </div>
                <div className="home__best-offers-action">
                    <Button onClick={handleNavigateToCatalog}>
                        {i18n.t('buy.bestOffers.button')}
                    </Button>
                </div>
            </section>
        }
        <section className="calculator">
            <div className="row center-xs center-sm center-md center-lg">
                <div className="col-xs-12 col-sm-12 col-sm-6 col-md-5 col-lg-5">
                    <h2>
                        <span>{i18n.t('buy.calculator.title1')} </span>
                        {i18n.t('buy.calculator.title2')}
                    </h2>
                </div>
            </div>
            <div className="row center-xs center-sm center-md center-lg">
                <div className="col-xs-12 col-sm-12 col-sm-6 col-md-4 col-lg-4">
                    <p>
                        {i18n.t('buy.calculator.description')}
                    </p>
                </div>
            </div>
            <div className="calculator-action">
                <Button onClick={handleOpenCalculator}>
                    {i18n.t('buy.calculator.button')}
                </Button>
            </div>
        </section>

    </div>)
});

export default Buy;
