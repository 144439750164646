import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import ImageGallery from 'react-image-gallery';
import {useLocation, useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {
    RealEstate, RealEstateExtra,
    RealEstatePreview,
    realEstateStoreContext,
    RealEstateType, realEstateTypes
} from '../../store/components/RealEstateStore';
import {currencyFormatter, extraIconsMap} from '../../utils/functions';
import {i18nContext} from '../../components/i18n/I18nProvider';
import './CatalogCardDetails.scss';
import Button from '../../components/ui/Button/Button';
import EmptyPlaceholder from '../Catalog/components/EmptyPlaceholder/EmptyPlaceholder';
import {observer} from 'mobx-react';
import {feedbackStoreContext} from '../../store/components/FeedbackStore';
import {Map, Marker, useMap} from '@vis.gl/react-google-maps';

type ImageGalleryType = {
    original: string;
    thumbnail: string
};

const CatalogCardDetails: React.FC = observer(() => {
    const map = useMap();
    const imageGalleryRef = useRef<any>();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {setFeedbackModalProps} = useOutletContext<any>();
    const {i18n, locale} = useContext(i18nContext);
    const feedbackStoreCtx = useContext(feedbackStoreContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const realEstates = useMemo(() => JSON.parse(JSON.stringify(realEstateTypes)).map((realEstate: RealEstateType) => {
        realEstate.name = i18n.translations[locale].realEstateTypes[realEstate.name];
        return realEstate;
    }), [locale, i18n.translations]);
    const getRealEstate = useCallback((realEstate: string) => {
        const realEstateType = realEstate;
        return realEstateType && realEstates.find((real: RealEstateType) => real.value === realEstateType);
    }, [realEstates]);
    const [isRealEstateNotExist, setRealEstateNotExist] = useState(false);

    const [realEstateInitBy, setRealEstateInitBy] = useState<RealEstateType | null>(getRealEstate(''));
    const [realEstate, setRealEstate] = useState<RealEstate>({} as RealEstate);
    const [images, setImages] = useState<{original: string; thumbnail: string}[]>([]);
    const [extrasActive, setExtrasActive] = useState<RealEstateExtra[]>([] as RealEstateExtra[]);
    const [isNavigateFromCatalog] = useState(location.key !== 'default');
    const [isRealEstateLoading, setRealEstateLoading] = useState(true);
    const [scale, setScale] = useState(7);

    useEffect(() => {
        realEstateStoreCtx.loadRealEstateExtras().then((extraList) => {
            realEstateStoreCtx.loadRealEstate(params.id as string)
                .then((realEstate: RealEstate) => {
                    if (!realEstate) {
                        setRealEstateLoading(false);
                        return setRealEstate({} as RealEstate);
                    }
                    setImages((realEstate.previews || []).reduce((acc, image: RealEstatePreview) => {
                        acc.push({original: image.imageUrl, thumbnail: image.imageUrl});
                        return acc;
                    }, [] as ImageGalleryType[]));
                    setRealEstate(realEstate);
                    setRealEstateInitBy(getRealEstate(realEstate.realEstateType));
                    setExtrasActive((realEstate.extras || []).reduce((acc, id) => {
                        acc.push((extraList || []).find((ex: RealEstateExtra) => Number(ex.id) === Number(id)));
                        return acc;
                    }, [] as RealEstateExtra[]));
                    setRealEstateLoading(false);
                }).catch(() => {
                    // @ts-ignore
                    setRealEstate({});
                    setRealEstateNotExist(true);
                    setRealEstateLoading(false);
                });
        });
    }, [params.id, realEstateStoreCtx, getRealEstate]);

    const handleGoBack = () => {
        if (isNavigateFromCatalog) {
            navigate(-1);
        } else {
            navigate(`/catalog?realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        }
    }

    const handleBuyRealEstate = () => {
        setFeedbackModalProps({isOpen: true, feedbackType: 'buy'});
        setTimeout(() => feedbackStoreCtx.merge({description: 'I want to buy ' + window.location.href}), 250);
    }

    if (isRealEstateLoading) {
        return (<div className="catalog-card-details">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    Loading...
                </div>
            </div>
        </div>);
    }

    if (!isRealEstateLoading && isRealEstateNotExist) {
        return (<div className="catalog-card-details">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <EmptyPlaceholder />
                </div>
            </div>
        </div>);
    }

    return (<div className="catalog-card-details">
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="back-catalog-navigation" onClick={handleGoBack}>
                    <i className="icon-arrow-left" />
                    {i18n.t(isNavigateFromCatalog ? 'catalogDetailsCard.backText' : 'catalogDetailsCard.goText')}
                </div>
                <div className="print-button" onClick={() => window.print()}>
                    <i className="icon-printer" />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 image-gallery-wrapper">
                <ImageGallery
                    ref={imageGalleryRef}
                    items={images}
                    showNav={true}
                    showPlayButton={false}
                    showFullscreenButton={true}
                    showBullets={true}
                    renderCustomControls={() => {
                        return <div className="image-gallery-overlay" onClick={() => imageGalleryRef.current.toggleFullScreen()}/>
                    }}
                />
                <div className="hide-xs hide-sm show-md show-lg">
                    {
                        locale === 'en' ?
                            // @ts-ignore
                             <div className="catalog-card-marketing-description" dangerouslySetInnerHTML={{__html: realEstate.realEstateMarketingDescription}} />
                            // @ts-ignore
                            : <div className="catalog-card-marketing-description" dangerouslySetInnerHTML={{__html: realEstate.realEstateMarketingDescriptionES}} />
                    }
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <div className="marketing-address">
                    {locale === 'en'
                        ? realEstate.realEstateMarketingName
                        : realEstate.realEstateMarketingNameES}
                </div>
                <div className="marketing-object-details">
                    <div className="real-estate-details-price">
                        {currencyFormatter(realEstate.catalogPrice || 0, true)}
                    </div>
                    <div className="real-estate-details-buy">
                        <Button
                            className={`button`}
                            onClick={handleBuyRealEstate}
                        >
                            BUY
                        </Button>
                    </div>
                </div>
                {
                    realEstate.latitude && realEstate.longitude && <div className="map-wrapper">
                        <Map
                            style={{width: '100%', height: '256px'}}
                            defaultCenter={{lat: realEstate.latitude, lng: realEstate.longitude}}
                            defaultZoom={15}
                            gestureHandling={'greedy'}
                            disableDefaultUI={true}
                            zoomControl={true}
                            onZoomChanged={(event) => {
                                setScale(event.detail.zoom >= 4 ? event.detail.zoom / 2 : 0);
                            }}
                        >
                            <Marker
                                position={{lat: realEstate.latitude, lng: realEstate.longitude}}
                                icon={{
                                    path: google.maps.SymbolPath.CIRCLE,
                                    scale: Math.pow(2, scale ?? 0) /  (10 / scale),
                                    strokeColor: "#FF0000",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: "#FF0000",
                                    fillOpacity: 0.35,
                                }}
                            />
                        </Map>
                        <div className="real-estate-address-location">
                            <span><i className="icon-location"/>{realEstate.presentationManualAddress}</span>
                        </div>
                    </div>
                }
                {
                    ((realEstate.bedroomCount && realEstate.bedroomCount > 0) || (realEstate.bathroomCount && realEstate.bathroomCount > 0) || (realEstate.livingArea && realEstate.livingArea > 0)) &&
                    <div className="real-estate-features">
                        {
                            realEstate.bedroomCount && realEstate.bedroomCount > 0 && <div className="real-estate-feature"><b>{realEstate.bedroomCount}</b> {i18n.t('catalogDetailsCard.card.bedrooms')}</div>
                        }

                        {
                            realEstate.bathroomCount && realEstate.bathroomCount > 0 && <div className="real-estate-feature"><b>{realEstate.bathroomCount}</b> {i18n.t('catalogDetailsCard.card.bathrooms')}</div>
                        }
                        {
                            realEstate.livingArea && realEstate.livingArea > 0 &&  <div className="real-estate-feature"><b>{realEstate.livingArea} m2</b> {i18n.t('catalogDetailsCard.card.livingArea')}</div>
                        }
                    </div>
                }
                <div className="real-estate-included">
                    <div className="catalog-card-details-features">
                        <div className="catalog-card-details-feature">
                            <div>
                                <i className={realEstateInitBy?.iconName} />
                            </div>
                            <div>
                                <div className="feature-title">{i18n.t('catalogDetailsCard.features.typology')}</div>
                                <div className="feature-description">{realEstateInitBy?.value}</div>
                            </div>
                        </div>
                        {
                            realEstate.floor &&
                            <div className="catalog-card-details-feature">
                                <div>
                                    <i className="icon-floor" />
                                </div>
                                <div>
                                    <div className="feature-title">{i18n.t('catalogDetailsCard.features.floor')}</div>
                                    <div className="feature-description">{realEstate.floor}{i18n.t('catalogDetailsCard.features.floorRD')}</div>
                                </div>
                            </div>
                        }
                        {
                            realEstate.energyCertificate && realEstate.energyClass &&
                            <div className="catalog-card-details-feature">
                                <div>
                                    <i className="icon-green" />
                                </div>
                                <div>
                                    <div className="feature-title">{i18n.t('catalogDetailsCard.features.energy')}</div>
                                    <div className="feature-description">{realEstate.energyClass} {i18n.t('catalogDetailsCard.features.energyClass')}</div>
                                </div>
                            </div>
                        }
                        {
                            realEstate.buildingYear &&
                            <div className="catalog-card-details-feature">
                                <div>
                                    <i className="icon-date" />
                                </div>
                                <div>
                                    <div className="feature-title">{i18n.t('catalogDetailsCard.features.building')}</div>
                                    <div className="feature-description">{realEstate.buildingYear}</div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="includes-extra-wrapper">
                        <div className="includes-extra">
                            {extrasActive.map((extra) => {
                                return (<div className="extra" key={extra.id}>
                                    <Button
                                        className={`button button--circle button--small button--ha-start button--light`}>
                                        {/*@ts-ignore*/}
                                        <i className={`${extraIconsMap[extra.id]}`}/>
                                        {i18n.t(`extras.${extra.name}`)}
                                    </Button>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="show-xs show-sm hide-md hide-lg">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    {
                        locale === 'en' ?
                            // @ts-ignore
                            <div className="catalog-card-marketing-description" dangerouslySetInnerHTML={{__html: realEstate.realEstateMarketingDescription}} />
                            // @ts-ignore
                            : <div className="catalog-card-marketing-description" dangerouslySetInnerHTML={{__html: realEstate.realEstateMarketingDescriptionES}} />
                    }
                </div>
            </div>
        </div>
    </div>)
});

export default CatalogCardDetails;
