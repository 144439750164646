import React, {useContext, useState} from 'react';
import './ExtraServices.scss';
import Button from '../../components/ui/Button/Button';
import {i18nContext} from '../../components/i18n/I18nProvider';
import {useOutletContext} from 'react-router-dom';
import i1 from './images/01.webp';
import i2 from './images/02.webp';
import i3 from './images/03.webp';
import i4 from './images/04.webp';
import i5 from './images/05.webp';
import parallax from '../Buy/images/parallax.webp';

const ExtraServices: React.FC<any> = (props) => {
    const {i18n} = useContext(i18nContext);
    const {setFeedbackModalProps} = useOutletContext<any>();
    const [extrasState, setExtrasState] = useState({
        insurance: true,
        obtaining: false,
        transaction: false,
        notary: false,
        registration: false,
    })
    const handleOpenFeedbackModal = (type: string) => {
        setFeedbackModalProps({isOpen: true, feedbackType: type});
    }

    const handleToggleSection = (sectionName: string) => {
        // @ts-ignore
        setExtrasState({...extrasState, ...{[sectionName]: !extrasState[sectionName]}});
    }

    return (<div className="extra-services">
        <div data-comment="hidden images preloader" style={{position: 'absolute', top: -9999, left: -9999, visibility: 'hidden', height: 0, width: 0}}>
            <img src={i1} alt={''}/>
            <img src={i2} alt={''}/>
            <img src={i3} alt={''}/>
            <img src={i4} alt={''}/>
            <img src={i5} alt={''}/>
        </div>
        <section className="insurance">
            <div className={`section-toggle`}
                 onClick={() => handleToggleSection('insurance')}>
                <h3>{i18n.t('extraServices.insurance.title')}</h3>
                <i className={extrasState.insurance ? "icon-minus" : "icon-plus"} />
            </div>
            <div className={`section-toggle-container ${extrasState.insurance ? '' : 'hidden'}`}>
                <div>
                    <p>
                        {i18n.t('extraServices.insurance.description1')}
                    </p>
                    <p>
                        {i18n.t('extraServices.insurance.description2')}
                    </p>
                    <ul>
                        <li>{i18n.t('extraServices.insurance.list.1')}</li>
                        <li>{i18n.t('extraServices.insurance.list.2')}</li>
                        <li>{i18n.t('extraServices.insurance.list.3')}</li>
                        <li>{i18n.t('extraServices.insurance.list.4')}</li>
                        <li>{i18n.t('extraServices.insurance.list.5')}</li>
                        <li>{i18n.t('extraServices.insurance.list.6')}</li>
                    </ul>
                </div>
                <div >
                    <div className="extra-services-image">
                        <img src={i1} alt={''}/>
                    </div>
                    <Button onClick={() => handleOpenFeedbackModal('insurance')}>{i18n.t('extraServices.insurance.button')}</Button>
                </div>
            </div>
        </section>

        <section className="obtaining">
            <div className={`section-toggle`}
                 onClick={() => handleToggleSection('obtaining')}>
                <h3>{i18n.t('extraServices.obtaining.title')}</h3>
                <i className={extrasState.obtaining ? "icon-minus" : "icon-plus"} />
            </div>
            <div className={`section-toggle-container ${extrasState.obtaining ? '' : 'hidden'}`}>
                <div>
                    <p>
                        {i18n.t('extraServices.obtaining.description1')}
                    </p>
                    <p>
                        {i18n.t('extraServices.obtaining.description2')}
                    </p>
                    <ul>
                        <li>{i18n.t('extraServices.obtaining.list.1')}</li>
                        <li>{i18n.t('extraServices.obtaining.list.2')}</li>
                        <li>{i18n.t('extraServices.obtaining.list.3')}</li>
                        <li>{i18n.t('extraServices.obtaining.list.4')}</li>
                    </ul>
                </div>
                <div >
                    <div className="extra-services-image">
                        <img src={i2} alt={''}/>
                    </div>
                    <Button onClick={() => handleOpenFeedbackModal('obtaining')}>{i18n.t('extraServices.obtaining.button')}</Button>
                </div>
            </div>
        </section>

        <section className="transaction">
            <div className={`section-toggle`}
                 onClick={() => handleToggleSection('transaction')}>
                <h3>{i18n.t('extraServices.transaction.title')}</h3>
                <i className={extrasState.transaction ? "icon-minus" : "icon-plus"} />
            </div>
            <div className={`section-toggle-container ${extrasState.transaction ? '' : 'hidden'}`}>
                <div>
                    <p>
                        {i18n.t('extraServices.transaction.description1')}
                    </p>
                    <p>
                        {i18n.t('extraServices.transaction.description2')}
                    </p>
                    <ul>
                        <li>{i18n.t('extraServices.transaction.list.1')}</li>
                        <li>{i18n.t('extraServices.transaction.list.2')}</li>
                        <li>{i18n.t('extraServices.transaction.list.3')}</li>
                        <li>{i18n.t('extraServices.transaction.list.4')}</li>
                        <li>{i18n.t('extraServices.transaction.list.5')}</li>
                    </ul>
                </div>
                <div >
                    <div className="extra-services-image">
                        <img src={i3} alt={''}/>
                    </div>
                    <Button onClick={() => handleOpenFeedbackModal('transaction')}>{i18n.t('extraServices.transaction.button')}</Button>
                </div>
            </div>
        </section>

        <section className="notary">
            <div className={`section-toggle`}
                 onClick={() => handleToggleSection('notary')}>
                <h3>{i18n.t('extraServices.notary.title')}</h3>
                <i className={extrasState.notary ? "icon-minus" : "icon-plus"} />
            </div>
            <div className={`section-toggle-container ${extrasState.notary ? '' : 'hidden'}`}>
                <div>
                    <p>
                        {i18n.t('extraServices.notary.description1')}
                    </p>
                    <p>
                        {i18n.t('extraServices.notary.description2')}
                    </p>
                    <ul>
                        <li>{i18n.t('extraServices.notary.list.1')}</li>
                        <li>{i18n.t('extraServices.notary.list.2')}</li>
                        <li>{i18n.t('extraServices.notary.list.3')}</li>
                        <li>{i18n.t('extraServices.notary.list.4')}</li>
                    </ul>
                </div>
                <div >
                    <div className="extra-services-image">
                        <img src={i4} alt={''}/>
                    </div>
                    <Button onClick={() => handleOpenFeedbackModal('notary')}>{i18n.t('extraServices.notary.button')}</Button>
                </div>
            </div>
        </section>

        <section className="registration">
            <div className={`section-toggle`}
                 onClick={() => handleToggleSection('registration')}>
                <h3>{i18n.t('extraServices.registration.title')}</h3>
                <i className={extrasState.notary ? "icon-minus" : "icon-plus"} />
            </div>
            <div className={`section-toggle-container ${extrasState.registration ? '' : 'hidden'}`}>
                <div>
                    <p>
                        {i18n.t('extraServices.registration.description1')}
                    </p>
                    <p>
                        {i18n.t('extraServices.registration.description2')}
                    </p>
                    <ul>
                        <li>{i18n.t('extraServices.registration.list.1')}</li>
                        <li>{i18n.t('extraServices.registration.list.2')}</li>
                        <li>{i18n.t('extraServices.registration.list.3')}</li>
                        <li>{i18n.t('extraServices.registration.list.4')}</li>
                    </ul>
                </div>
                <div >
                    <div className="extra-services-image">
                        <img src={i5} alt={''}/>
                    </div>
                    <Button onClick={() => handleOpenFeedbackModal('registration')}>{i18n.t('extraServices.registration.button')}</Button>
                </div>
            </div>
        </section>

        <section className="home__parallax mt-48">
            <div className="row">
                <div className="col">
                    <img src={parallax} alt={'domus'}/>
                </div>
            </div>
        </section>
    </div>);
};

export default ExtraServices;
